@font-face {
  font-family: 'linkedbold';
  src: url('../font/EBold.ttf') format('truetype');
  /* Add additional font formats if necessary */
}

@font-face {
  font-family: 'linkedmedium';
  src: url('../font/EMedium.ttf') format('truetype');
  /* Add additional font formats if necessary */
}

@font-face {
  font-family: 'linkedlight';
  src: url('../font/ELight.ttf') format('truetype');
  /* Add additional font formats if necessary */
}


body {
  margin: 0;
  font-family: "linkedbold";
padding: 0;
}

.list{
  font-family: 'linkedlight';
  font-size: small;
}
.animated-button {
  color: #fff;
  font-size: 1.2rem;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  animation: dance 1s infinite alternate ease-in-out;
}

@keyframes dance {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}

.animated-button:focus {
  outline: none;
}

.animated-button:active {
  animation-play-state: paused; /* Pause animation on click */
}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color: #FFF #FFF transparent transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after,
.loader::before {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px solid;
  border-color: transparent transparent #FEC629 #FEC629;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotationBack 0.5s linear infinite;
  transform-origin: center center;
}
.loader::before {
  width: 32px;
  height: 32px;
  border-color: #FFF #FFF transparent transparent;
  animation: rotation 1.5s linear infinite;
}
    
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
    


@layer base {

  .section {
    @apply mx-auto py-24 px-6
  }

  .animate-slide:hover {
    animation-play-state: paused;
  }
}




@tailwind base;
@tailwind components;
@tailwind utilities;